
import './App.css';
import Imagecomponent from './component/Imagecomponent';
import Data from "./data/Data"
function App() {
  return (
    <>
      <div className=" ">
        {Data.map((e) => {
          return <>
            <Imagecomponent url1={e[0]} url2={e[1]} url3={e[2]} url4={e[3]} url5={e[4]} url6={e[5]} />
           
          </>
        })}


      </div>
    </>
  );
}

export default App;
