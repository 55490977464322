import React from 'react'
function Imagecomponent(props) {
    return (
        <>
            <section class="overflow-hidden text-gray-700">
                <div class="container px-5 py-2 mx-auto lg:pt-24 lg:px-32">
                    <div class="flex flex-wrap -m-1 md:-m-2">
                        <div class="flex flex-wrap w-1/2">
                            <div class="w-1/2 p-1 md:p-2">
                                <img alt="gallery" class="hover:scale-75 hover:cursor-pointer transition duration-700 ease-in-out block object-fit object-center w-full h-full rounded-lg"
                                    src={`https://drive.google.com/uc?export=view&id=${props.url1}`} />
                            </div>
                            <div class="w-1/2 p-1 md:p-2">
                                <img alt="gallery" class="hover:scale-125 hover:cursor-pointer transition duration-700 ease-in-out block object-cover object-center w-full h-full rounded-lg"
                                    src={`https://drive.google.com/uc?export=view&id=${props.url2}`} />
                            </div>
                            <div class="w-full p-1 md:p-2">
                                <img alt="gallery" class="hover:scale-125 hover:cursor-pointer transition duration-700 ease-in-out block object-cover object-center w-full h-full rounded-lg"
                                    src={`https://drive.google.com/uc?export=view&id=${props.url3}`} />
                            </div>
                        </div>
                        <div class="flex flex-wrap w-1/2">
                            <div class="w-full p-1 md:p-2">
                                <img alt="gallery" class="hover:scale-75 hover:cursor-pointer transition duration-700 block object-cover object-center w-full h-full rounded-lg"
                                    src={`https://drive.google.com/uc?export=view&id=${props.url4}`} />
                            </div>
                            <div class="w-1/2 p-1 md:p-2">
                                <img alt="gallery" class="hover:scale-75 hover:cursor-pointer transition duration-700 ease-in-out block object-cover object-center w-full h-full rounded-lg"
                                    src={`https://drive.google.com/uc?export=view&id=${props.url5}`} />
                            </div>
                            <div class="w-1/2 p-1 md:p-2">
                                <img alt="gallery" class="hover:scale-125 hover:cursor-pointer transition duration-700 block object-cover object-center w-full h-full rounded-lg"
                                    src={`https://drive.google.com/uc?export=view&id=${props.url6}`} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Imagecomponent;